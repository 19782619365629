import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from 'styled-components';

import { MotionDiv, MotionImage } from '../../animations/components';
import { animationContainer, fadeInLeft, fadeInRight, fadeInUp } from '../../animations/variants';
import pdfBike from '../../assets/guides/Gogeta Bike - Employer Guide.pdf';
import pdfNursery from '../../assets/guides/Gogeta Nursery - Employer Guide.pdf';
import pdfTech from '../../assets/guides/Gogeta Tech & Home - Employer Guide.pdf';
import image2 from '../../assets/images/going-bread-like.png';
import image from '../../assets/images/invite-employee.png';
import { ScContainer } from '../../components/container/styled';
import { CustomButton } from '../../components/custom-button';
import { ScParagraph } from '../../components/Paragraph/styled';
import { ScTitle } from '../../components/Title/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import { useMatchMedia } from '../../hooks/use-match-media';
import { download } from '../../utils/download';
import {
	ScReadyToStartSavingButtonsWrapper,
	ScReadyToStartSavingTextWrapper,
} from '../Home/styled';

import { ScInviteEmployeeWrapper } from './styled';

export enum Schema {
	NURSERY =  'NURSERY',
	CYCLE = 'CYCLE',
	TECH = 'TECH',
	HOME = 'HOME'
}

export const InviteYourEmployer = ({ schema } : { schema: Schema }) => {
	const theme = useTheme();
	const isDesktop = useMatchMedia(`(min-width: 1220px)`);
	const { ref, controls } = useContentAnimation();

	return (
		<motion.section initial="hidden" animate={controls} variants={animationContainer}>
			<ScContainer>
				<ScInviteEmployeeWrapper>
					<ScReadyToStartSavingTextWrapper>
						<MotionDiv variants={fadeInRight}>
							<ScTitle isMobileLarge color={theme.colors.strongWhite} centered={!isDesktop}>
								Invite your
								{isDesktop && <br />}
								employer
							</ScTitle>
						</MotionDiv>
						<MotionDiv variants={fadeInRight}>
							<ScParagraph color={theme.colors.strongWhite} centered={!isDesktop} className="text">
								{schema === Schema.NURSERY
									? 'Let your employer know you want to use Gogeta Nursery. Download our short guide to share with them or simply send them a link to this website. They can apply in minutes online.'
									: (schema === Schema.CYCLE)
									? 'Let your employer know you want to use Gogeta Bike. Download our short guide to share with them or simply send them a link to this website. They can apply in minutes online.'
									: (schema === Schema.TECH)
									?	'Let your employer know you want to use Gogeta Tech. Download our short guide to share with them or simply send them a link to this website. They can apply in minutes online.'
									: 'Let your employer know you want to use Gogeta Home. Download our short guide to share with them or simply send them a link to this website. They can apply in minutes online.'
								}
							</ScParagraph>
						</MotionDiv>
						{!isDesktop && (
							<MotionImage
								variants={fadeInUp}
								src={schema === Schema.NURSERY ? image : (schema === Schema.CYCLE) ? image2 : image}
								alt="ready to start saving image"
							/>
						)}
						<MotionDiv variants={fadeInRight}>
							<ScReadyToStartSavingButtonsWrapper ref={ref}>
								<CustomButton
									color={theme.colors.darkText}
									background={theme.colors.strongWhite}
									onClick={() =>
										schema === Schema.NURSERY ? download(pdfNursery as string) : (schema === Schema.CYCLE) ? download(pdfBike as string) : download(pdfTech as string)
									}
								>
									Get our employer guide
								</CustomButton>
							</ScReadyToStartSavingButtonsWrapper>
						</MotionDiv>
					</ScReadyToStartSavingTextWrapper>
					{isDesktop && (
						<MotionImage
							variants={fadeInLeft}
							src={schema === Schema.NURSERY ? image : (schema === Schema.CYCLE) ? image2 : image}
							alt="ready to start saving image"
						/>
					)}
				</ScInviteEmployeeWrapper>
			</ScContainer>
		</motion.section>
	);
};
