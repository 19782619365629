import React from 'react';
import { motion } from 'framer-motion';
import { animationContainer } from '../../animations/variants';
import { ScContainer } from '../../components/container/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import { ScBenefitsContainer, ScBenefitsWrapper } from './styled';
import homeDough from '../../assets/images/home_dough.png';
import superImg from '../../assets/images/super-img.png';
import { BenefitsCard, IBenefitsCard } from '../../components/BenefitsCard';
const items: IBenefitsCard[] = [
	{
		title: 'Where can I spend?',
		text: 'You can buy your Home items at John Lewis or Ikea. You can save on big home purchases, such as a new sofa, bed or dining table and also spread the cost over 12 months.',
		image: homeDough,
	},
	{
		title: 'How do I sign up?',
		text: "To use Gogeta Home and save up to 8% on home purchases, your employer needs to sign up. It's simple, quick and doesn't cost them a penny. Follow the link at the bottom of this page to get going.",
		image: superImg,
		link: '/employer-tech/',
		buttonText: 'Let’s get started',
	},
];
export const Benefits = () => {
	const { ref, controls } = useContentAnimation();

	return (
		<ScBenefitsContainer>
			<ScBenefitsWrapper>
				<ScContainer>
					<motion.section
						ref={ref}
						initial="hidden"
						animate={controls}
						variants={animationContainer}
					>
						{items.map(({ title, text, image, link, buttonText }, index) => (
							<BenefitsCard
								key={title}
								title={title}
								text={text}
								image={image}
								link={link}
								buttonText={buttonText}
								inverted={index % 2 === 0}
								lastElement={index === items.length - 1}
							/>
						))}
					</motion.section>
				</ScContainer>
			</ScBenefitsWrapper>
		</ScBenefitsContainer>
	);
};
